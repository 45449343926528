import React from 'react'
import { graphql } from 'gatsby'
import { Router } from '@reach/router'
import { Helmet } from 'react-helmet'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'
import AvatarCard from '../../components/AvatarCard/AvatarCard'
import Mississippi from '../../components/Mississippi/Mississippi'
import StateRepCard from '../../components/StateRepCard/StateRepCard'

const CountyInfo = (props) => {
  const senators = props.senators.filter(senator => senator.counties.some(county => county.name === props.county))
  const representatives = props.reps.filter(representative => representative.counties.some(county => county.name === props.county))
  let countyNoSpace = props.county.replaceAll(' ', '_')

  return (
    <div className="center-text">
      <h3>State Senators for {props.county} County</h3>
      <a href={`http://www.msjrc.state.ms.us/senate/senate_${countyNoSpace}.html`} target="_blank" rel="noreferrer">County Senate District Map</a>
      <div className="flex-row justify-space-around">
        {senators.map(senator => (
          <StateRepCard rep={senator} senator />
        ))}
      </div>
      <h3>State Representatives for {props.county} County</h3>
      <a href={`http://www.msjrc.state.ms.us/house/house_${countyNoSpace}.html`} target="_blank" rel="noreferrer">County House of Representatives District Map</a>
      <div className="flex-row justify-space-around">
        {representatives.map(rep => (
          <StateRepCard rep={rep} representative />
        ))}
      </div>
    </div >
  )
}

const MSLeaders = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        <h2>Statewide Elected Officials</h2>
        <div className="flex-row justify-space-around">
          <AvatarCard member={data.markdownRemark.frontmatter.governor} position="Governor" />
          <AvatarCard member={data.markdownRemark.frontmatter.ltGovernor} position="Lt. Governor" />
          <AvatarCard member={data.markdownRemark.frontmatter.secOfState} position="Secretary of State" />
          <AvatarCard member={data.markdownRemark.frontmatter.attorneyGeneral} position="Attorney General" />
          <AvatarCard member={data.markdownRemark.frontmatter.stateAuditor} position="State Auditor" />
          <AvatarCard member={data.markdownRemark.frontmatter.treasurer} position="State Treasurer" />
          <AvatarCard member={data.markdownRemark.frontmatter.commAg} position="Commissioner of Agriculture" />
          <AvatarCard member={data.markdownRemark.frontmatter.commIns} position="Commissioner of Insurance" />
        </div>
        <h2>Elected State Representation by County</h2>
        <p className="center-text">{data.markdownRemark.frontmatter.instructions}</p>
        <Mississippi state />
        <Router basepath="/education/ms-representation/">
          <CountyInfo path="/:county" senators={data.markdownRemark.frontmatter.senators} reps={data.markdownRemark.frontmatter.representatives} />
        </Router>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query MSLeadersQuery {
  markdownRemark(frontmatter: {title: {eq: "MS Leaders"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      governor {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
      ltGovernor {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
      secOfState {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
      attorneyGeneral {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
      stateAuditor {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
      treasurer {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
      commAg {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
      commIns {
        name
        party
        photo {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 1
              transformOptions: {fit: INSIDE, cropFocus: ATTENTION, trim: 10}
            )
          }
        }
      }
      instructions
      representatives {
        name
        district
        counties {
          name
        }
        party
        addr1
        addr2
        city
        state
        zip
        phone
        email
      }
      senators {
        name
        district
        counties {
          name
        }
        party
        addr1
        addr2
        city
        state
        zip
        phone
        email
      }
    }
  }
}
`

export default MSLeaders