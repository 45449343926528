import React from 'react'

import { formatPhoneNumber } from '../../utilities/formatPhoneNumber'

import * as styles from './StateRepCard.module.css'

const StateRepCard = (props) => {
  return (
    <div className={styles.rep_card}>
      <h4>{props.senator ? 'Sen. ' : null}{props.representative ? 'Rep. ' : null}{props.rep.name}</h4>
      <small>{props.rep.party}</small>
      <span>District {props.rep.district}</span>
      <h5>Address</h5>
      {props.rep.addr1}<br />
      {props.rep.addr2 ? <><span>{props.rep.addr2}</span><br /></> : null}
      {props.rep.city}, {props.rep.state} {props.rep.zip}
      {props.rep.phone ? (
        <>
          <h5>Phone</h5>
          <a href={`tel:+1${props.rep.phone}`}>{formatPhoneNumber(props.rep.phone.toString())}</a>
        </>
      )
        : null}
      {props.rep.email ? (
        <>
          <h5>Email</h5>
          <a href={`mailto:${props.rep.email}`}>{props.rep.email}</a>
        </>
      ) : null}
    </div>
  )
}

export default StateRepCard